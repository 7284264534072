import React from "react";
import PageWrapper from "../components/PageWrapper";

const Contact = () => {
  return (
    <>
      <PageWrapper>
        <div className="bg-default-2 pt-16 pb-12 pt-lg-22 pb-lg-27">
          <div className="container">
            <div className="row justify-content-center mt-14">
              <div className="col-xxl-6 col-xl-7 col-lg-8">
                <h2 className="font-size-9 text-center mb-11">Submit a Job</h2>
                <div className="bg-white px-9 pt-9 pb-7 shadow-8 rounded-4">
                  <form
                    name="submit-job"
                    method="post"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                  >
                    {/* You still need to add the hidden input with the form name to your JSX form */}
                    <input type="hidden" name="form-name" value="submit-job" />
                    <div className="row">
                    <div className="col-12 mb-7">
                        <label
                          htmlFor="company-name"
                          className="font-size-4 font-weight-semibold text-black-2 mb-5 line-height-reset"
                        >
                          Company Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="XYZ Company"
                          id="company-name"
                          name="company-name"
                          required
                        />
                      </div>
                      <div className="col-12 mb-7">
                        <label
                          htmlFor="name"
                          className="font-size-4 font-weight-semibold text-black-2 mb-5 line-height-reset"
                        >
                          Your Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Jhon Doe"
                          id="name"
                          name="name"
                          required
                        />
                      </div>
                      <div className="col-12 mb-7">
                        <label
                          htmlFor="email"
                          className="font-size-4 font-weight-semibold text-black-2 mb-5 line-height-reset"
                        >
                          E-mail
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="example@gmail.com"
                          id="email"
                          name="email"
                          required
                        />
                      </div>
                      <div className="col-12 mb-7">
                        <label
                          htmlFor="job-title"
                          className="font-size-4 font-weight-semibold text-black-2 mb-5 line-height-reset"
                        >
                          Job Title
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Job Title"
                          id="job-title"
                          name="job-title"
                          required
                        />
                      </div>
                      <div className="col-lg-12 mb-7">
                        <label
                          htmlFor="job-description"
                          className="font-size-4 font-weight-semibold text-black-2 mb-5 line-height-reset"
                        >
                          Job Description
                        </label>
                        <textarea
                          id="job-description"
                          placeholder="Type your job description"
                          className="form-control h-px-144"
                          name="job-description"
                          required
                        ></textarea>
                      </div>
                      <div className="col-lg-12 mb-7">
                        <label
                          htmlFor="job-hours"
                          className="font-size-4 font-weight-semibold text-black-2 mb-5 line-height-reset"
                        >
                          Hours Per Week
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Hours per week"
                          id="job-hours"
                          name="job-hours"
                          required
                        />
                      </div>
                      <div className="col-lg-12 pt-4">
                        <button
                          type="submit"
                          className="btn btn-primary text-uppercase w-100 h-px-48"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default Contact;
